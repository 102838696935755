import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function ExportList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "module", sortable: false, label: "Module" },
        { key: "file_name", sortable: false, label: "File Name" },
        { key: "completed", sortable: false, label: "Completed" },
        { key: "mime_type", sortable: false, label: "Mime Type" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalExportList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const exportsMetaData = ref([]);
    const exportStatusOption = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalExportList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statusFilter], () => {
        refetchData();
    });

    const fetchExportList = (ctx, callback) => {
        let data = {};
        data.url = "/exports";
        data.params = {
            q: searchQuery.value,
            perPage: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            status: statusFilter.value
        };
        store
            .dispatch("app/get", data)
            .then(response => {
                const data = response.data.data;
                const { total } = response.data.meta;
                exportStatusOption.value = response.data.status;

                callback(data);
                totalExportList.value = total;
                exportsMetaData.value = data;
                let refetch = data.filter(
                    (item) => item.completed != 1

                );
                if (refetch.length > 0) {
                    setTimeout(function () { refetchData() }, 5000);
                }
            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching exports",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == false) return "danger";
        if (status == true) return "success";
        return "primary";
    };

    return {
        fetchExportList,
        tableColumns,
        perPage,
        currentPage,
        totalExportList,
        exportsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        exportStatusOption,
        statusFilter
    };
}
